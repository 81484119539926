import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import pl from 'vuetify/lib/locale/pl'
import uk  from 'vuetify/lib/locale/uk'
import de  from 'vuetify/lib/locale/de'
import en  from 'vuetify/lib/locale/en'
import store from '@/store/store.js'

Vue.use(Vuetify)

const vuetify = new Vuetify(
  {
    icons: {
      iconfont: 'mdi',
    },
    theme: {
      dark: store.state.dark,
      themes: {
        light: {
          primary: '#3399ff',
          secondary: '#424242',
          accent: '#82B1FF',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
        },
        dark: {
          primary: '#ffd600',
          secondary: '#424242',
          accent: '#82B1FF',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
        }
      }
    },
    lang: {
      locales: { pl, uk, de, en },
      current: 'pl'
    }
  }
)

export default vuetify
