import axios from 'axios/index'
import store from '@/store/store.js'
import {i18n} from '../i18n.js'

const geolocation = {
  state: {
    isSearching: false,
    userPosition: null,
    currentLocationBorough: null,
    currentLocationCounty: null,
    currentLocationVoivodeship: null,
    currentLocationCountry: null,
    currentLocationObject: null
  },
  mutations: {
    setIsSearching (state, newValue) {
      state.isSearching = newValue
    },
    setCurrentLocationBorough (state, newValue) {
      state.currentLocationBorough = newValue
    },
    setCurrentLocationCounty (state, newValue) {
      state.currentLocationCounty = newValue
    },
    setCurrentLocationVoivodeship (state, newValue) {
      state.currentLocationVoivodeship = newValue
    },
    setCurrentLocationCountry (state, newValue) {
      state.currentLocationCountry = newValue
    },
    setCurrentLocationObject (state, newValue) {
      state.currentLocationObject = newValue
    },
    setUserPosition (state, newValue) {
      state.userPosition = newValue
    }
  },
  actions: {
    getCurrentLocation (context, options = {}) {
      return new Promise((resolve, reject) => {
        if (!('geolocation' in window.navigator)) {
          throw new Error(i18n.t('store.noSupportGPS'))
        } else {
          window.navigator.geolocation.getCurrentPosition(
            position => {
              resolve(
                context.commit('setUserPosition', position)
              )
            },
            (reject) => {
              throw new Error(i18n.t('store.noRightsGPS') + reject)
            },
            options
          )
        }
      })
    },
    async reverseGeocoding (context) { // TODO SPLIT
      await axios.get('https://maps.googleapis.com/maps/api/geocode/json?', {
        params: {
          latlng: context.state.userPosition.coords.latitude + ',' + context.state.userPosition.coords.longitude,
          result_type: 'administrative_area_level_3|administrative_area_level_2|administrative_area_level_1|country',
          key: 'AIzaSyAswPGUD0Z999yNPBzMgy7nCTlzoeW27DA',
          language: 'pl'
        }
      }).then((response) => {
        if (response.data.status === 'OK') {
          for (let i in response.data.results) {
            if (response.data.results[i].types[0] === 'administrative_area_level_3') {
              context.commit('setCurrentLocationBorough', response.data.results[i].address_components[0].long_name)
            }
            if (response.data.results[i].types[0] === 'administrative_area_level_2') {
              context.commit('setCurrentLocationCounty', response.data.results[i].address_components[0].long_name.replace('powiat ', '').replace('Powiat ', ''))
            }
            if (response.data.results[i].types[0] === 'administrative_area_level_1') {
              context.commit('setCurrentLocationVoivodeship', response.data.results[i].address_components[0].long_name.replace('województwo ', '').replace('Województwo ', ''))
            }
            if (response.data.results[i].types[0] === 'country') {
              context.commit('setCurrentLocationCountry', response.data.results[i].address_components[0].long_name)
            }
          }
        } else {
          throw new Error(i18n.t('store.googleLocalizeError'))
        }
      }).catch((error) => {
        throw error
      })
    },
    async getLocation (context, commit, rootState) {
      try {
        context.commit('setIsSearching', true)
        await store.dispatch('getCurrentLocation')
        await store.dispatch('reverseGeocoding')
        if (context.state.currentLocationCountry !== 'Polska') {
          context.commit('setAlert', {
            type: 'info',
            visible: true,
            message: i18n.t('store.outsideOfPoland')
          })
          context.commit('setIsSearching', false)
          return
        }
        let wojewodztwo = context.rootState.geoportale.wojewodztwa.find(
          wojewodztwo => {
            if (wojewodztwo.nazwa.toLowerCase() === context.state.currentLocationVoivodeship.toLowerCase()) {
              return wojewodztwo
            }
          }
        )
        if (wojewodztwo === undefined) {
          context.commit('setAlert', {
            type: 'info',
            visible: true,
            message: i18n.t('store.noGeoportalInVoivodeships') + context.state.currentLocationVoivodeship
          })
          context.commit('setIsSearching', false)
          return
        }
        let powiat = wojewodztwo.powiaty.find(
          powiat => {
            if (powiat.nazwa.toLowerCase().includes(context.state.currentLocationBorough.toLowerCase())) {
              return powiat
            } else if (powiat.nazwa.toLowerCase().includes(context.state.currentLocationCounty.toLowerCase())) {
              return powiat
            }
          }
        )
        if (powiat === undefined) {
          context.commit('setAlert', {
            type: 'info',
            visible: true,
            message: i18n.t('store.noGeoportalInCounty') + context.state.currentLocationCounty
          })
          context.commit('setIsSearching', false)
          return
        }
        context.commit('setIsSearching', false)
        context.commit('setCurrentLocationObject', powiat)
      } catch (e) {
        context.commit('setIsSearching', false)
        context.commit('setAlert', { type: 'error', visible: true, message: e.message })
        console.error(e)
      }
    }
  }
}

export default geolocation
