import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/store.js'

const LastUsedList = () => import(/* webpackChunkName: "LastUsedList" */ '@/views/LastUsedList')
const Counties = () => import(/* webpackChunkName: "Counties" */ '@/views/Counties')
const Settings = () => import(/* webpackChunkName: "Settings" */ '@/views/Settings')
const Home = () => import(/* webpackChunkName: "Home" */ '@/views/Home')
const Root = () => import(/* webpackChunkName: "Root" */ '@/views/Root')
const CountiesMap = () => import(/* webpackChunkName: "CountiesMap" */ '@/views/CountiesMap')
const NotFound = () => import(/* webpackChunkName: "NotFound" */ '@/views/NotFound')
const ServiceManual = () => import(/* webpackChunkName: "ServiceManual" */ '@/views/ServiceManual.vue')
const ServiceList = () => import(/* webpackChunkName: "ServiceList" */ '@/views/ServiceList.vue')
const NationalGeoportal = () => import(/* webpackChunkName: "NationalGeoportal" */ '@/views/NationalGeoportal.vue')

Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return window.scrollTo({ top: document.querySelector(to.hash).offsetTop, behavior: 'smooth' })
    } else if (to.matched.some(m => m.meta.scrollToTop)) {
      return window.scrollTo({ top: 0, behavior: 'smooth' })
    } else {
      return false
    }
  },
  routes: [
    {
      path: '/:lang([a-z]{2})',
      component: Root,
      props: route => ({ lang: route.params.lang }),
      children: [{
        path: '',
        name: 'Home',
        component: Home,
        beforeEnter: (to, from, next) => {
          store.commit('setShowSearchIcon', false)
          store.commit('setShowSearchBox', false)
          next()
        },
        meta: {
          key: 'home',
          scrollToTop: true,
          }
      },
        {
          path: 'mapa-geoportali',
          name: 'CountiesMap',
          component: CountiesMap,
          beforeEnter: (to, from, next) => {
            store.commit('setShowSearchIcon', false)
            store.commit('setShowSearchBox', false)
            store.commit('updateSearchValue', '')
            next()
          },
          meta: {
            key: 'countiesmap',
            scrollToTop: true,
            }
        },
        {
          path: 'ostatnio-uzywane',
          name: 'LastUsedList',
          component: LastUsedList,
          beforeEnter: (to, from, next) => {
            store.commit('setShowSearchIcon', false)
            store.commit('setShowSearchBox', false)
            next()
          },
          meta: {
            key: 'ostatnio-uzywane',
            scrollToTop: true,
            }
        },
        {
          path: 'instrukcje', // TODO przebudować na usluga/instrukcja
          name: 'ServiceList',
          component: ServiceList,
          beforeEnter: (to, from, next) => {
            store.commit('setShowSearchIcon', false)
            store.commit('setShowSearchBox', false)
            next()
          },
          meta: {
            key: 'instrukcje',
            scrollToTop: true,
          }
        },
        {
          path: 'instrukcje/:tutorial', // TODO przebudować na usluga/instrukcja
          name: 'ServiceManual',
          component: ServiceManual,
          beforeEnter: (to, from, next) => {
            store.commit('setShowSearchIcon', false)
            store.commit('setShowSearchBox', false)
            next()
          },
          meta: {
            key: 'instrukcja',
            scrollToTop: true,
          }
        },
        {
          path: 'ustawienia',
          name: 'Settings',
          component: Settings,
          beforeEnter: (to, from, next) => {
            store.commit('setShowSearchIcon', false)
            store.commit('setShowSearchBox', false)
            next()
          },
          meta: {
            key: 'ustawienia',
            scrollToTop: true,
            }
        },
        {
          path: 'lista-geoportali',
          name: 'Counties',
          component: Counties,
          beforeEnter: (to, from, next) => {
            store.commit('setShowSearchIcon', true)
            store.commit('setShowSearchBox', false)
            store.commit('updateSearchValue', '')
            next()
          },
          meta: {
            key: 'lista-geoportali',
            scrollToTop: true,
            }
        },
        {
          path: 'geoportal-krajowy',
          name: 'NationalGeoportal',
          component: NationalGeoportal,
          beforeEnter: (to, from, next) => {
            store.commit('setShowSearchIcon', false)
            store.commit('setShowSearchBox', false)
            next()
          },
          meta: {
            key: 'geoportal-krajowy',
            scrollToTop: true,
            }
        },
        {
          path: '404',
          name: 'NotFound',
          component: NotFound,
          beforeEnter: (to, from, next) => {
            store.commit('setShowSearchIcon', false)
            store.commit('setShowSearchBox', false)
            next()
          },
          meta: {
            key: '404',
            scrollToTop: true,
            }
        },
        { path: '*', redirect: { name: 'NotFound', params: { lang: store.state.currentLanguage } } }
      ]
    },
    {
      path: '*',
      redirect: to => {
        let lang = store.state.currentLanguage || 'pl'
        return `/${lang}${to.path}`
      }
    }
  ]
})
