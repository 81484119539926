<template>
  <v-app>
    <v-navigation-drawer
      v-model="menuDrawer"

      app
      clipped
      fixed
    >
      <v-list
        dense
        subheader
      >
        <v-subheader>{{ $t('app.firstSubheader') }}</v-subheader>
        <v-list-item
          :to="{ name: 'Home', params: { lang: $i18n.locale } }"
          exact
        >
          <v-list-item-action>
            <v-icon>mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('app.home') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-subheader>{{ $t('app.secondSubheader') }}</v-subheader>
        <v-list-item :to="{ name: 'NationalGeoportal', params: { lang: $i18n.locale } }">
          <v-list-item-action>
            <svg
              style="height:24px"
              enable-background="new 0 0 600 600"
              viewBox="0 0 600 600"
              xmlns="http://www.w3.org/2000/svg"
            ><path
              d="m183 142.2 7 4.2 4.8 3s6.6 8.6 8.4 8.4c1.8-.1 0-6 0-6l1.8-8.4 3.6-6-2.4-6.6s-2.7 3.2-6 1.8-7.2-5.4-7.2-5.4l-6 4.2-6-3v-12l6 4.2 6 1.8 9-3 10.8-.6 12-1.8 11.4-2.4 15.6-4.2 10.8-2.4 9.6-1.8 12.6-1.8s7.4 0 11.4-3 6-9 6-9l7.8-6.6 9.6-6.6 10.2-3.6 13.2-1.2 15-3 13.2-2.4 16.8-.6 12-3 6-3 10.2-3 9-.6 16.8 9.6 6.6 6.6s3.4 4.5 5.4 9c.6 1.4-3.6-.6-3.6-.6l-2.7-6.3-3.3-3.9-9-6.9-8.4-3.9v9l.6 9 1.8 9 .6 9 6.6 6 5.4 6 11.4 1.8 9-.6 14.4-3 8.4-3.6s4.3-5.2 7.8-5.4c3.5-.1 6 4.8 6 4.8l27 3 18 6 26.4 4.2 22.8 3.6 13.8 2.4 15 1.8h18l9-3h9l12-3 8 .1 11.2 6.5 4.8 8.4 9 6 4.5 5.2 1.5 12.8v12l3.8 8.4.5 14.4 3.3 9.6 1.5 15 4.2 10.8s.6 6.3 1.1 10c.8 5.5 1.9 2.3 2.3 7.8.3 4.1 2.7 4.3 3 8.3.2 4-1 6.9.8 11 1.7 3.8 5.4 10.8 5.4 10.8l-3.6 8.4-8.4 7.8s-4.8 4.4-9 6-3.9 4.2-8.4 7.2-8.4 8.4-8.4 8.4-8.7 4.4-7.2 9.6 12.6 10.2 12.6 10.2l10.2 7.2 2.4 12v15l-.8 14.9 1 10.7-1.2 14.4 3 12 6 12 6 6 6 12 3 6 6 4.8c-2.2.6-3.8 2.3-6 1.2l-6 9 6 9 9 6-6 12-6.6 1.2-18.8 17.7-19.4 20.1-18.1 18.3-18.8 21.9-8.4 4.8 7.8 12.6-3.6 7.8 1.8 15.6 7.2 10.2-.6 12.6-8.4-6.6-10.2-1.2-15-9-15-3-12-9-9-6-15-6-12-9-12 3-12 9-12 3-9-6-9-9-12 3-6 6-6 15-12-6-12 3 3-15-3-9-6-15-6-6-6 3-9 9-3 6h-6l-9 3-6.8-11c-1.5-5-2.2-14-2.2-14l-9-9v-9l-12-12-12-9-12-6-12 12-9-12-9-12 6-9 6-6-9-6h-12l-18-3s-9.8-9-12-9-1.1 2.7 3 11.4c1.9 4.1.8 5.4 3.3 8.4 2.3 2.7-.3 7.2-.3 7.2l-6-1.2-6 3.6s-5.5 7.5-9 6.6-7.8-9.6-7.8-9.6l-3-8.4-6-12-7.8-7.8 6-7.8s8.9-.9 9.6-5.4-4.2-9-4.2-9l-4.8-6-15-3-18-3-15-3-9-15-3-9-10.8-3s-2.7 1.8-3.6 4.2-.6 6-.6 6l-6-1.2s3.3-5.7 4.8-10.2 1.2-7.8 1.2-7.8l6.6-12.6s1.6-7-3.6-19.2c-2.6-6.1-5-12.4-8.9-17.5-3.9-5.2-2.6-9.2-5.5-14.3 1.3-5 2.4-11.4 2.4-11.4l9-12 3-12-3-6v-9l-6-9 .6-11.4 5.4-9.6s-2.6-3.2-6.3-6.9-8.7-8.1-8.7-8.1l-4.2-9-8.4-8.4 6-9s4.8-2.5 10.2-10.2c5.4-7.6 9.6-18 9.6-18l1.8-11.4-6-9v-22.1z"
              :fill="dark ? '#FFF' : '#000'"
              :fill-opacity="dark ? 1 : 0.4"
              transform="translate(-155.9 -51.0372)"
            /></svg>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('app.nationalGeoportal') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :to="{ name: 'LastUsedList', params: { lang: $i18n.locale } }"
        >
          <v-list-item-action>
            <v-icon>mdi-history</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('app.lastUsedList') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'Counties', params: { lang: $i18n.locale } }">
          <v-list-item-action>
            <v-icon>mdi-map</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('app.counties') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :to="{ name: 'CountiesMap', params: { lang: $i18n.locale } }"
        >
          <v-list-item-action>
            <v-icon>mdi-layers</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('app.countiesMap') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :to="{ name: 'ServiceList', params: { lang: $i18n.locale } }"
        >
          <v-list-item-action>
            <v-icon>mdi-help-circle</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('app.instrukcje') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-subheader>{{ $t('app.thirdSubheader') }}</v-subheader>
        <v-list-item :to="{ name: 'Settings', params: { lang: $i18n.locale } }">
          <v-list-item-action>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t('app.settings') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          href="https://geobid.pl/?utm_source=geoportal2pl&utm_medium=link"
        >
          <v-list-item-action>
            <img
              :alt="$t('app.logoGeobid')"
              src="@/assets/img/logoGeobid.svg"
              style="height:32px"
            >
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>GEOBID</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
        <v-subheader>{{ $t('app.fourthSubheader') }}</v-subheader>
        <v-list-item
          href="https://play.google.com/store/apps/details?id=pl.geobid.geoportal2&utm_source=geoportal2&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        >
          <v-list-item-content>
            <v-img
              :alt="$t('app.downloadFromGooglePlay')"
              :src="$t('app.imgGooglePlay')"
              max-width="150px"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      v-if="!showSearchBox"
      id="app-toolbar"
      app
      fixed
      clipped-left
    >
      <v-app-bar-nav-icon
        :aria-label="$t('app.showHideMenu')"
        @click.stop="menuDrawer = !menuDrawer"
      />
      <router-link
        :to="{ name: 'Home', params: { lang: $i18n.locale } }"
        :aria-label="$t('app.home')"
        :title="$t('app.home')"
      >
        <v-img
          :src="require(`@/assets/img/logoGeoportal2.svg`)"
          :alt="$t('app.logoGeoportal2')"
          contain
          transition="scale-transition"
          height="42px"
          width="42px"
        />
      </router-link>
      <v-toolbar-title class="text-h6">
        Geoportal2.pl
      </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-menu
          bottom="bottom"
          offset-y="offset-y"
          left="left"
          attach="attach"
        >
          <template #activator="{ on }">
            <v-btn
              text
              style="min-width: 48px;"
              v-on="on"
            >
              <img
                :src="require(`@/assets/img/flags/${$i18n.locale}.png`)"
                :alt="$i18n.locale"
                width="32px"
              >
            </v-btn>
          </template>
          <language-selector />
        </v-menu>
        <v-btn
          v-if="showSearchIcon"
          :aria-label="$t('app.showSearchToolbar')"
          text
          icon
          color="primary"
          @click.stop="showSearchToolbar"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <!-- Bar wyszukujący -->
    <v-app-bar
      v-if="showSearchBox"
      fixed
      app
      clipped-left
    >
      <v-text-field
        id="search"
        :value="searchValue"
        :append-outer-icon="voiceRecognitionIcon"
        :label="$t('app.search')"
        clearable
        autofocus
        background-color="transparent"
        single-line
        hide-details
        prepend-icon="mdi-arrow-left"
        @input="updateSearchValue"
        @keydown.esc="hideSearchToolbar"
        @click:prepend="hideSearchToolbar"
        @click:append-outer="voiceRecognition"
      />
    </v-app-bar>
    <v-main>
      <v-container fluid>
        <snackbar-service-worker />
        <v-alert
          v-model="alert.visible"
          :type="alert.type"
          dismissible
          transition="scale-transition"
        >
          {{ alert.message }}
        </v-alert>
        <v-fade-transition mode="out-in">
          <router-view :key="routerKey" />
        </v-fade-transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import {mapState, mapActions} from 'vuex'

  export default {
    name: 'App',
    components: {
      SnackbarServiceWorker: () =>
        import(/* webpackChunkName: "SnackbarServiceWorker" */ '@/components/SnackbarServiceWorker'),
      LanguageSelector: () =>
        import(/* webpackChunkName: "LanguageSelector" */ '@/components/LanguageSelector')
    },
    metaInfo () {
      return {
        title: this.$i18n.t('app.meta.title'),
        titleTemplate: '%s • GEOPORTAL 2'
      }
    },
    data: function () {
      return {
        menuDrawer: null,
        isRecognizing: false,
        recognition: undefined,
        baseUrl: process.env.BASE_URL
      }
    },
    computed: {
      ...mapState([
        'alert',
        'dark',
        'showSearchIcon',
        'showSearchBox',
        'searchValue'
      ]),
      voiceRecognitionIcon: function () {
        if (this.isRecognizing) {
          return 'mdi-voice'
        } else {
          if (
            typeof window !== 'undefined' &&
            (window.SpeechRecognition ||
              window.webkitSpeechRecognition ||
              window.mozSpeechRecognition ||
              window.msSpeechRecognition ||
              window.oSpeechRecognition)
          ) {
            return 'mdi-microphone'
          } else {
            return ''
          }
        }
      },
      routerKey: function () {
        return this.$route.meta.key !== undefined
          ? this.$route.meta.key
          : this.$route.path
      }
    },
    created () {
      this.updateGeoportale(this.baseUrl + 'geoand.json')
      const BrowserSpeechRecognition =
        typeof window !== 'undefined' &&
        (window.SpeechRecognition ||
          window.webkitSpeechRecognition ||
          window.mozSpeechRecognition ||
          window.msSpeechRecognition ||
          window.oSpeechRecognition)
      this.recognition = BrowserSpeechRecognition
        ? new BrowserSpeechRecognition()
        : null
      if (this.recognition !== null) {
        this.recognition.lang = 'pl-PL'
        this.recognition.continuous = false
        this.recognition.interimResults = false
        this.recognition.onstart = () => {
          this.isRecognizing = true
        }
        this.recognition.onend = () => {
          this.isRecognizing = false
        }
        this.recognition.onerror = event => {
          this.isRecognizing = false
        }
        this.recognition.onresult = event => {
          this.$store.commit('updateSearchValue', event.results[0][0].transcript)
        }
      }
    },
    methods: {
      ...mapActions(['updateGeoportale']),
      changeLanguage: function (lang) {
        this.$router.replace({params: {lang}})
      },
      updateSearchValue: function (e) {
        this.$store.commit('updateSearchValue', e)
      },
      showSearchToolbar: function () {
        this.$store.commit('setShowSearchIcon', false)
        this.$store.commit('setShowSearchBox', true)
      },
      hideSearchToolbar: function () {
        this.$store.commit('setShowSearchIcon', true)
        this.$store.commit('updateSearchValue', '')
        this.$store.commit('setShowSearchBox', false)
      },
      voiceRecognition: function () {
        if (this.isRecognizing) {
          this.recognition.stop()
        } else {
          this.recognition.start()
        }
      }
    }
  }
</script>

<style lang="sass">
  #app-toolbar
    .v-toolbar__title
      margin-left: .5em
</style>
