import Vue from 'vue'
import VueI18n from 'vue-i18n'
import pl from './locales/pl'
import axios from 'axios'
import store from './store/store'

const messages = {
  'pl': {
    ...pl
  }
}

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'pl',
  fallbackLocale: 'en',
  messages
})

const loadedLanguages = ['pl']

function setI18nLanguage (lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  return lang
}

export function loadLanguageAsync (lang) {
  if (i18n.locale !== lang) {
    store.commit('updateCurrentLanguage', lang)
    if (!loadedLanguages.includes(lang)) {
      return import(/* webpackChunkName: "locales-[request]" */ `@/locales/${lang}`).then(msgs => {
        i18n.setLocaleMessage(lang, msgs.default)
        loadedLanguages.push(lang)
        return setI18nLanguage(lang)
      })
    }
    return Promise.resolve(setI18nLanguage(lang))
  }
  return Promise.resolve(lang)
}
