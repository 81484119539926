import 'core-js/stable'
import 'regenerator-runtime/runtime'
import './registerServiceWorker'
import Vue from 'vue'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import router from './router/index.js'
import store from './store/store.js'
import Meta from 'vue-meta'
import VueClipboard from 'vue-clipboard2'
import {i18n, loadLanguageAsync} from './i18n'
import VueObserveVisibility from 'vue-observe-visibility'
import languages from './languages'
import VueYoutube from 'vue-youtube'


Vue.use(Meta)
Vue.use(VueClipboard)
Vue.use(VueObserveVisibility)
Vue.use(VueYoutube)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  const lang = to.params.lang
  if(languages.some(element =>
    element.locale.includes(lang)
  )) {
    loadLanguageAsync(lang).then(() => next())
  } else {
    router.push({name: 'Home', params: { lang: i18n.locale } })
  }
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
