const languages = [
  {
    "name": "polski",
    "locale": "pl"
  },
  {
    "name": "English",
    "locale": "en"
  },
  {
    "name": "Deutsch",
    "locale": "de"
  },
  {
    "name": "český",
    "locale": "cs"
  },
  {
    "name": "Український",
    "locale": "uk"
  }
]

export default languages
