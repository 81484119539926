import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import latinize from 'latinize'
import axios from 'axios'
import geolocation from './geolocation'
import createMutationsSharer from 'vuex-shared-mutations'
import geoand from '@/assets/geoand'
import powiaty from '@/assets/powiaty_bbox'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: [
        'lastCountiesArray',
        'dark',
        'numberLastUsed',
        'currentLanguage'
      ]
    }),
    createMutationsSharer(
      { predicate: ['addNewCounty', 'updateDark', 'clearLastCounties'] }
    )],
  state: {
    alert: {
      type: null,
      message: '',
      visible: false
    },
    geoportale: geoand,
    powiaty: powiaty,
    lastCountiesArray: [],
    showSearchIcon: false,
    showSearchBox: false,
    activeCountyOnMap: null,
    searchValue: null,
    dark: true,
    numberLastUsed: 10,
    snackbar: false,
    currentLanguage: 'pl'
  },
  modules: {
    geolocation: geolocation
  },
  getters: {
    getLastCountiesArray (state) {
      return state.lastCountiesArray.slice(0, state.numberLastUsed)
    },
    getDark (state) {
      return state.dark
    },
    getNumberLastUsed (state) {
      return state.numberLastUsed
    },
    getGeoportale (state) {
      if (state.searchValue !== null && state.searchValue !== '') {
        let wojewodztwa = state.geoportale.wojewodztwa.map(
          wojewodztwo => {
            let rObj = {}
            rObj.TERYT = wojewodztwo.TERYT
            rObj.nazwa = wojewodztwo.nazwa
            if (latinize(wojewodztwo.nazwa.toLowerCase()).indexOf(latinize(state.searchValue.toLowerCase())) === 0) {
              rObj.powiaty = wojewodztwo.powiaty
              return rObj
            } else {
              rObj.powiaty = wojewodztwo.powiaty.filter(
                powiat => latinize(powiat.nazwa).toLowerCase().includes(latinize(state.searchValue).toLowerCase())
              )
              return rObj
            }
          }
        ).filter(wojewodztwo => wojewodztwo.powiaty.length > 0)
        return { wojewodztwa }
      } else {
        return state.geoportale
      }
    }
  },
  mutations: {
    addNewCounty (state, county) {
      let index = state.lastCountiesArray.findIndex(
        function (item) {
          return item.TERYT === county.TERYT
        })
      if (index > -1) {
        state.lastCountiesArray.splice(index, 1)
      }
      state.lastCountiesArray.unshift(county)
    },
    clearLastCounties (state) {
      state.lastCountiesArray = []
    },
    setShowSearchIcon (state, newValue) {
      state.showSearchIcon = newValue
    },
    setShowSearchBox (state, newValue) {
      state.showSearchBox = newValue
    },
    updateSearchValue (state, newValue) {
      state.searchValue = newValue
    },
    updateCurrentLanguage (state, newValue) {
      state.currentLanguage = newValue
    },
    updateDark (state) {
      state.dark = !state.dark

    },
    setNumberLastUsed (state, newValue) {
      state.numberLastUsed = newValue
    },
    setGeoportale (state, newValue) {
      state.geoportale = newValue
    },
    setPowiaty (state, newValue) {
      state.powiaty = newValue
    },
    setAlert (state, newValue) {
      state.alert = newValue
    },
    showSnackBar (state) {
      state.snackbar = true
    },
    hideSnackBar (state) {
      state.snackbar = false
    },
    setActiveCountyOnMap (state, newValue) {
      state.activeCountyOnMap = newValue
    }
  },
  actions: {
    async updateGeoportale (context, filePath) {
      await context.dispatch('updatePowiaty')
      const response = await axios.get(filePath)
      let geoportale = context.state.powiaty
      geoportale.wojewodztwa.forEach((wojewodztwo, indexWojewodztwo) => {
        wojewodztwo.powiaty.forEach((powiat, indexPowiat) => {
          const geoportalCounty = response.data.wojewodztwa.flatMap(voivodeship => voivodeship.powiaty).find(foundPowiat => foundPowiat.TERYT === powiat.TERYT);
          if (geoportalCounty) {
            geoportale.wojewodztwa[indexWojewodztwo].powiaty[indexPowiat].adresy = geoportalCounty.adresy
          }
        })
      })
      context.commit('setGeoportale', geoportale)
    },
    async updatePowiaty (context) {
      const response = await axios.get(process.env.BASE_URL + 'powiaty_bbox.json')
      context.commit('setPowiaty', response.data)
    }
  }
})

export default store
